import React, { useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { Box } from '@mui/material'

import { ProductList } from './productList'
import { ServiceList } from './service'


export const Change: React.FC = () => {
  const { product } = useParams()
  const { pathname } = useLocation()

  const renderContent = useCallback(() => {
    switch (pathname) {
      case '/change':
        return <ServiceList />

      default:
        return <ProductList />
    }
  }, [product])

  return <Box>{renderContent()}</Box>
}

import React from 'react'

import { Box, Container } from '@mui/material'

import { CustomTitle } from 'src/components/customTitle'

import { ourContactUs } from 'src/utils/constants/common'

import styles from './contactUs.module.scss'

export const ContactUs: React.FC = () => {
  return (
    <Box>
      <Container>
      <CustomTitle title={'Наші контакти'} />

        <Box>
          <table className={styles.table}>
            <tbody>
              {ourContactUs.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Box>{el.type}</Box>
                    </td>
                    <td>
                      <Box>{el.value}</Box>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Box>

        <iframe
          // eslint-disable-next-line max-len
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1268.5883407044416!2d30.456687958303206!3d50.51227930052274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4d27e757d0d57%3A0x5f2eb88179a7c746!2z0YPQuy4g0JTRg9Cx0YDQvtCy0LjRhtC60LDRjywgMjgsINCa0LjQtdCyLCAwNDExNA!5e0!3m2!1sru!2sua!4v1675954817214!5m2!1sru!2sua'
          width='600'
          height='450'
          style={{ border: '0', width: '100%', marginTop: 60, minHeight: '600px' }}
          allowFullScreen={true}
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </Container>
    </Box>
  )
}

import React from 'react'

import { Box, Typography } from '@mui/material'

import styles from './titleMain.module.scss'

export const TitleMain = () => {
  return (
    <>
      <Box className={styles.title}>
        <Typography variant='h2'>VentBudEngineering Company</Typography>
      </Box>
      <Box className={styles.subTitle}>
        <Typography variant='h3'>
          Компанія заснована в 2023 році – одна з профільних Українських фірм по виготовленню
          комплектуючих для систем вентиляції та кондиціонування повітря, монтажних та ремонтних
          робіт на ринку України
        </Typography>
      </Box>
    </>
  )
}

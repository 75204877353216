import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button } from '@mui/material'

import styles from './card.module.scss'

interface IProps {
  images: React.ReactNode
  link: string
  withoutBtn?: boolean
}

export const SliderCard: React.FC<IProps> = ({ images, link, withoutBtn }) => {
  const navigate = useNavigate()

  return (
    <Box className={styles.root}>
      <Box
        sx={{
          padding: '43%',
          background: `url(${images}) center no-repeat`,
          backgroundSize: 'cover'
        }}
      />

      {!withoutBtn && (
        <Button
          variant='contained'
          className={styles.btn}
          size='medium'
          onClick={() => {
            navigate(link)
          }}
        >
          Детальніше
        </Button>
      )}
    </Box>
  )
}

import { responsiveFontSizes, createTheme, Theme } from '@mui/material/styles'

import variables from '../styles/variables.scss'

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      // extra-small
      xs: +variables.xs,
      // small
      sm: +variables.sm,
      // medium
      md: +variables.md,
      // large
      lg: +variables.lg,
      // extra-large
      xl: +variables.xl
    }
  }
})

const { breakpoints } = defaultTheme

let theme: Theme = createTheme({
  ...defaultTheme,

  spacing: (value: number) => value * 8,
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          color: '#eca10c'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: `${+variables.containerSize}px !important`
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: '24px',
          lineHeight: 1.5,
          fontWeight: 600,
          textTransform: 'uppercase',
          [breakpoints.up('xl')]: {
            fontSize: 24
          },
          [breakpoints.down('xl')]: {
            fontSize: 24
          },
          [breakpoints.down('md')]: {
            fontSize: 18
          }
        },
        h3: {
          lineHeight: 1.5,
          fontWeight: 400,
          textTransform: 'uppercase',
          [breakpoints.up('xl')]: {
            fontSize: 14
          },
          [breakpoints.down('xl')]: {
            fontSize: 14
          },
          [breakpoints.down('md')]: {
            fontSize: 12
          }
        },
        body1: {
          fontSize: '16px',
          lineHeight: 1.8,
          [breakpoints.down('lg')]: {
            fontSize: '14px'
          }
        },
        body2: {
          fontSize: '16px',
          lineHeight: 1.8
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          fontWeight: 600,
          fontSize: '16px',
          borderRadius: '20px',
          backgroundColor: '#2398DB',

          '&:hover': {
            backgroundColor: '#1596c0'
          }
        },
        contained: {
          // small: {
          // },
          // medium: {
          // }
        },
        sizeMedium: {
          maxWidth: '200px'
        },
        sizeLarge: {
          maxWidth: '300px',
          fontSize: '18px'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          justifyContent: 'start'
        }
      }
    }
  },
  typography: {
    h5: {
      fontSize: '14px',
      lineHeight: 1.55,
      fontWeight: 400,
      color: '#FFFFFF'
    }
  }
})

theme = responsiveFontSizes(theme)

export { theme }

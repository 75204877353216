/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import CachedIcon from '@mui/icons-material/Cached'
import { Box, Container, Button } from '@mui/material'
import { deleteObject, ref } from 'firebase/storage'
import { storage } from 'src/firebase-config'

import {
  deleteDocumentById,
  getProductByTag,
  updateNestingInDocument
} from 'src/lib/firebase/grid.services'

import { CustomBreadcrumbs } from 'src/components/breadcrumbs'
import { CatalogCard } from 'src/components/catalogCard'
import { CustomTitle } from 'src/components/customTitle'
import { Loader } from 'src/components/loader'

import { ModalChange } from '../modal'
import { getCatalogName } from 'src/utils/parse/catalog'

import styles from './productList.module.scss'

export const ProductList: React.FC = () => {
  const [title, setTitle] = useState('')
  const [loader, setLoader] = useState(false)
  const [content, setContent] = useState<ICatalogItem[]>([])
  const [isModal, setIsModal] = useState(false)
  const [crumbs, setCrumbs] = useState([
    {
      name: '',
      link: ''
    }
  ])

  const { product, type } = useParams()
  const { pathname } = useLocation()

  const deleteImgFromStorage = (url: string[]) =>
    Promise.all(
      url.map((el) => {
        const desertRef = ref(storage, el)

        deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
          })
          .catch((error) => {
            alert(`Щось пішло не так: ${error.message}`)
          })
      })
    )

  const handleDelItem = async (id: string) => {
    setLoader(true)

    if (type) {
      try {
        const delElement = content.filter((el) => {
          return el.id === id
        })

        delElement[0].img.length > 0 && (await deleteImgFromStorage(delElement[0].img))
        !!delElement[0].tableImg && (await deleteImgFromStorage([delElement[0].tableImg]))

        const newArray = content.filter((el) => {
          return el.id !== id
        })

        const res = Object.fromEntries(newArray.map((k) => [k.id, k]))

        await updateNestingInDocument(product || '', type, res)
        setLoader(false)
        getContent()
      } catch (error) {
        alert(`Щось пишло не так ${error}`)
        setLoader(false)
      }
    } else {
      try {
        const delElement = content.filter((el) => {
          return el.id === id
        })

        delElement[0].img.length > 0 && (await deleteImgFromStorage(delElement[0].img))
        !!delElement[0].tableImg && (await deleteImgFromStorage([delElement[0].tableImg]))

        const nestingList = Object.values(delElement[0].nesting)

        nestingList.forEach(async (el) => {
          el.img.length > 0 && (await deleteImgFromStorage(el.img))
          !!el.tableImg && (await deleteImgFromStorage([el.tableImg]))
        })

        deleteDocumentById(product || '', id)
        setLoader(false)
        getContent()
      } catch (error) {
        alert(`Щось пишло не так ${error}`)
        setLoader(false)
      }
    }
  }

  const getCardLink = (name: string) => {
    if (type) return pathname

    return `${pathname}/${name}`
  }

  const renderContent = (res: ICatalogItem[]) => {
    setCrumbs([
      {
        name: getCatalogName(product),
        link: `/change/${product}`
      }
    ])
    if (!!type) {
      res.forEach((el: ICatalogItem) => el.id === type && setContent(Object.values(el.nesting)))

      const itemName = res
        .map((el: ICatalogItem) => el.id === type && el.name)
        .filter((el) => !!el)
        .join()
      setTitle(itemName)
      setCrumbs((prev) => {
        return [...prev, { link: `/change/${product}`, name: itemName }]
      })
    } else {
      setContent(res)
      setTitle(getCatalogName(product))
    }
  }

  const getContent = async () => {
    setLoader(true)

    try {
      const res = await getProductByTag(product || '')
      renderContent(res as ICatalogItem[])
    } catch (error) {
      return alert(`Щось пішло не так: ${error}`)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getContent()
  }, [product, type])

  return (
    <Box>
      {loader ? (
        <Loader />
      ) : (
        <Container>
          {isModal && (
            <ModalChange
              isOpen={isModal}
              setModalClose={setIsModal}
              nesting={content}
              getContent={getContent}
            />
          )}
          <CustomTitle title={title} />

          <CustomBreadcrumbs list={crumbs} mode={'change'} />

          <Button
            sx={{ margin: 'auto', color: '#FFF', marginBottom: 3, marginTop: 3 }}
            onClick={getContent}
          >
            <CachedIcon />
          </Button>

          <Box className={styles.cardContainer}>
            {content &&
              content.length > 0 &&
              content.map((el, index) => {
                return (
                  <Box key={index} className={styles.cardBlock}>
                    <Box
                      className={styles.cardDelete}
                      onClick={() => {
                        handleDelItem(el.id)
                      }}
                    >
                      +
                    </Box>
                    <CatalogCard
                      key={index}
                      name={el.name}
                      images={el.img[0]}
                      link={getCardLink(el.id)}
                    />
                  </Box>
                )
              })}
            <Box
              className={styles.addCard}
              onClick={() => {
                setIsModal(true)
              }}
            >
              +
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  )
}

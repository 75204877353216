import React, { BaseSyntheticEvent, SyntheticEvent, useRef } from 'react'

import { Box, Button } from '@mui/material'

interface IProps {
  onChange: (file: File) => void
  btnText: string
  maxSize?: number
}

export const ImageUploader: React.FC<IProps> = ({ onChange, maxSize = 1000, btnText }) => {
  const inputEl = useRef<HTMLInputElement>(null)

  const handleChange = (e: BaseSyntheticEvent) => {
    const size = e.target.files[0].size * Math.pow(10, -6)
    if (size <= maxSize) {
      onChange(e.target.files[0])
    } else {
      alert(`Maximum size is ${maxSize} Mb!`)
    }
  }

  return (
    <Box>
      <input
        ref={inputEl}
        type='file'
        style={{ display: 'none' }}
        onChange={handleChange}
        accept='.png, .jpg'
      />
      <Button
        sx={{ width: 'auto', color: '#FFF' }}
        onClick={(e: SyntheticEvent) => {
          inputEl.current?.click()
          e.preventDefault()
        }}
      >
        {btnText}
      </Button>
    </Box>
  )
}

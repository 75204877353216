import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

export const Page404: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Typography variant='h1'>404</Typography>
      <Typography variant='h6'>Сторінка, яку ви шукаєте, не існує.</Typography>
      <Button
        variant='contained'
        onClick={() => {
          navigate('/')
        }}
      >
        На головну!
      </Button>
    </Box>
  )
}

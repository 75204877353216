import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import { AuthProvider } from './containers/authContext'
import { theme } from './lib/mui'

import Routers from './routes'

import './App.css'

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Routers />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App

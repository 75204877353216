import main1 from 'src/assets/mainPage/slider1.jpg'
import main2 from 'src/assets/mainPage/slider2.jpg'
import main3 from 'src/assets/mainPage/slider3.jpg'
import main4 from 'src/assets/mainPage/slider4.jpg'

export const sliderCards = [
  {
    img: main1,
    hash: 'first'
  },
  {
    img: main2,
    hash: 'second'
  },
  {
    img: main3,
    hash: 'third'
  },
  {
    img: main4,
    hash: 'fourth'
  }
]

import { useState, useEffect } from 'react'

import { debounce } from 'lodash'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window

  return {
    width,
    height
  }
}

export const useWindowDimensions = (timeout = 500) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowDimensions(getWindowDimensions())
    }, timeout)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

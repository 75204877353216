import React, { useEffect, useState } from 'react'
import Zoom from 'react-medium-image-zoom'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Container, Typography } from '@mui/material'

import { getProductByKey } from 'src/lib/firebase/grid.services'

import { CustomTitle } from 'src/components/customTitle'
import { Loader } from 'src/components/loader'

import { DetailsCalc } from './parts/calc'
import { SliderDetails } from './parts/slider'

import { linksForCalc } from 'src/utils/constants/common'

import 'react-medium-image-zoom/dist/styles.css'
import styles from './details.module.scss'

export const Details: React.FC = () => {
  const [loader, setLoader] = useState(false)
  const [item, setItem] = useState<ICatalogItem>()
  const navigate = useNavigate()

  const { product, type, model } = useParams()

  const getContent = async () => {
    setLoader(true)

    try {
      const res = await getProductByKey(product || '', type || '')

      if (!res) navigate('/404')

      if (!!model) {
        setItem(res?.nesting[model])
      } else {
        setItem(res as ICatalogItem)
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      return alert(`Щось пішло не так: ${error}`)
    }
  }

  useEffect(() => {
    getContent()
  }, [])

  return (
    <Box>
      {loader || !item ? (
        <Loader />
      ) : (
        <Container>
          <CustomTitle title={item?.title || ''} />

          <Box className={styles.contentContainer}>
            <Box className={styles.sliderContainer}>
              <Box className={styles.sliderBlock}>
                <SliderDetails img={item.img} />
              </Box>
            </Box>
            <Box className={styles.calcContainer}>
              {linksForCalc.includes(type as string) ? (
                <DetailsCalc item={item as ICatalogItem} />
              ) : (
                item.tableImg && (
                  <Box className={styles.tableContainer}>
                    <Typography variant='h2' sx={{ marginBottom: '20px' }}>
                      Ціна з пдв:
                    </Typography>
                    <Zoom>
                      <img alt='' src={item.tableImg} className={styles.zoomImg} />
                    </Zoom>
                  </Box>
                )
              )}
            </Box>
          </Box>

          <Box className={styles.descriptionBlock}>
            <Typography variant='h2'>Детальніше: </Typography>

            {item.description.map((el, index) => (
              <Typography key={index} variant='body2' sx={{ marginTop: 2 }}>
                {el}
              </Typography>
            ))}
          </Box>
        </Container>
      )}
    </Box>
  )
}

import React from 'react'

import { Box, Container } from '@mui/material'

import { SliderMain, TitleMain, Benefits, MoreInfo } from './parts'

import styles from './main.module.scss'

export const Main: React.FC = () => {
  return (
    <Box>
      <Container>
        <TitleMain />
      </Container>
      <Box className={styles.block}>
        <Container>
          <SliderMain />
        </Container>
      </Box>
      <Box className={styles.block}>
        <Container>
          <Benefits />
        </Container>
      </Box>
      <Box className={styles.block}>
        <MoreInfo />
      </Box>
    </Box>
  )
}

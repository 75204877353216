import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCOGflN2t2trI2fowOkSq2Is5IOEeOUbq4',
  authDomain: 'vent-bud-eng.firebaseapp.com',
  databaseURL: 'https://vent-bud-eng-default-rtdb.firebaseio.com',
  projectId: 'vent-bud-eng',
  storageBucket: 'vent-bud-eng.appspot.com',
  messagingSenderId: '431058401796',
  appId: '1:431058401796:web:a39aa7d4682ccbe5291351'
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth(app)

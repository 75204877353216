import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined'
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded'
import PlaceIcon from '@mui/icons-material/Place'

export const contactInfo = [
  {
    type: 'address',
    text: 'Україна - Київ - Дубровицька, 28',
    icon: PlaceIcon,
    to: '/contact-us',
    href: ''
  },
  {
    type: 'email',
    text: 'vbe23@ukr.net',
    icon: LocalPostOfficeOutlinedIcon,
    to: '',
    href: 'mailto:vbe23@ukr.net'
  },
  {
    type: 'phone',
    text: '+38(096)368-70-27',
    icon: PhoneRoundedIcon,
    to: '',
    href: 'tel:+380963687027'
  }
]

export const ourContactUs = [
  {
    type: 'Фактична адреса:',
    value: 'вулиця Дубровицька, 28, Київ, Офіс 311'
  },
  {
    type: 'Телефони моб.:',
    value: '(098)368-70-27 (Viber/Telegram)'
  },
  {
    type: 'Email',
    value: 'vbe23@ukr.net'
  }
]

export const nav = [
  {
    text: 'Головна',
    link: '/'
  },
  {
    text: 'Решітки та діфузори',
    link: '/catalog/grid'
  },
  {
    text: 'Повітропроводи',
    link: '/about/airDucts'
  },
  {
    text: 'Комплектуючі вентиляції',
    link: '/catalog/ventAccessories'
  },
  {
    text: 'Димохідні системи',
    link: '/about/chimneySystems'
  },
  {
    text: 'Послуги',
    link: '/services'
  },
  {
    text: 'Контакти',
    link: '/contact-us'
  }
]

export const navChange = [
  {
    text: 'Решітки та діфузори',
    link: '/grid'
  },
  {
    text: 'Комплектуючі вентиляції',
    link: '/ventAccessories'
  }
]

export const gridsSizeRec = [100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000]
export const gridsSizeSquare = [150, 225, 300, 350, 400, 450, 485, 550, 600]
export const crossingSqrBySize = {
  'kd-pdk': {
    150: '0.018',
    225: '0.034',
    300: '0.054',
    350: '0.075',
    400: '0.099',
    450: '0.108',
    485: '0.134',
    550: '0.168',
    600: '0.180'
  },
  'kd-pdk-demfer': {
    150: '0.016',
    225: '0.029',
    300: '0.045',
    350: '0.064',
    400: '0.084',
    450: '0.088',
    485: '0.113',
    550: '0.139',
    600: '0.146'
  },
  'kd-panel': {
    150: '0.018',
    225: '0.034',
    300: '0.054',
    350: '0.075',
    400: '0.099',
    450: '0.108',
    485: '0.134',
    550: '0.168',
    600: '0.180'
  },
  'kd-pdk-demfer-panel': {
    150: '0.016',
    225: '0.029',
    300: '0.045',
    350: '0.064',
    400: '0.084',
    450: '0.088',
    485: '0.113',
    550: '0.139',
    600: '0.146'
  }
}

export const crossingRec = {
  'odnoryadni-pryamokutni': '0.75',
  'dvoryadni-pryamokutni': '0.65',
  'zovnishnya-reshetka': '0.8'
}

export const linksForCalc = [
  'odnoryadni-pryamokutni',
  'dvoryadni-pryamokutni',
  'zovnishnya-reshetka',
  'kvadratnij-difuzor'
]

import React from 'react'

import { Typography, Box } from '@mui/material'

import gjvitroprovidkrug from 'src/assets/airDucts/gjvitroprovidkrug.jpg'
import povitroprovidpryam from 'src/assets/airDucts/povitroprovidpryam.jpg'

import styles from './airDucts.module.scss'

export const AirDucts: React.FC = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Typography variant='body2'>
          Повітропроводи прямокутного типу та круглі прямошовні використовуються у системах
          вентиляції та кондиціювання повітря для транспортування повітря з метою його обробки,
          видалення, або подачі до приміщень. Повітропроводи прямокутної форми є зручними з
          міркувань економії простору, дизайну інтер’єрів, монтажу і під’єднання до решіток та інших
          елементів вентиляційної системи. Повітропроводи круглого перерізу є оптимальними у
          вентиляції, так як дають змогу створити будь-яку конфігурацію вентиляційних мереж, а також
          є простими, легкими і швидкими для монтажу. Фасонні вироби – елементи вентиляційних
          систем, необхідних для створення та сполучення різних вузлів між собою фасонними частинами
          відповідного перетину. Повітропроводи виготовляються в різних розмірах довжиною: 1м та
          1.25 м.
        </Typography>
        <Typography variant='body2'>
          Адаптер круглого перетину - використовується для плавного переходу одного перерізу
          повітроводу на інший і збереження оптимальної швидкості потоку в системах вентиляції.
          Адаптер круглого перетину - використовується для плавного переходу одного перерізу
          повітроводу на інший і збереження оптимальної швидкості потоку в системах вентиляції.
        </Typography>
      </Box>

      <Box className={styles.images}>
          <img src={gjvitroprovidkrug} alt='' />
          <img src={povitroprovidpryam} alt='' />
      </Box>
    </Box>
  )
}

import React from 'react'

import { Box, Typography } from '@mui/material'

import styles from './chimneySystems.module.scss'

export const ChimneySystems: React.FC = () => {
  return (
    <>
      <Box className={styles.content}>
        <Typography variant='body2'>
          Димохідні системи з нержавіючої сталі одностінні та двістінні.
        </Typography>

        <Typography variant='body2'>
          Виготовлення димоходів з нержавіючої сталі - це грамотне інженерне рішення та отримання
          надійної модульної системи. Користь двостінних систем також полягає в тому, що вони
          практично зводять нанівець негативний вплив конденсату. Високий рівень ізоляції не дає
          газам остигати, що також сприяє більшій ефективності роботи системи.
        </Typography>

        <Box className={styles.listContainer}>
          <Box>
            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
              Одностінні системи :
            </Typography>

            <ul className={styles.list}>
              <li>Труба димохідна</li>
              <li>Коліно</li>
              <li>Трійник</li>
              <li>Грибок для труби</li>
            </ul>
          </Box>
          <Box>
            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
              Двостінні теплоізольовані :
            </Typography>

            <ul className={styles.list}>
              <li>Труба димохідна</li>
              <li>Коліно</li>
              <li>Трійник</li>
            </ul>
          </Box>
        </Box>
      </Box>
    </>
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { navChange } from 'src/utils/constants/common'

export const ServiceList: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '20px'
    }}>
      {navChange.map((el, index) => (
        <Typography
        variant='h2'
          key={index}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/change${el.link}`)
          }}
        >
          {el.text}
        </Typography>
      ))}
    </Box>
  )
}

export const getCatalogName = (type?: string): string => {
  switch (type) {
    case 'grid':
      return 'Вентиляційні решітки'
    case 'airDucts':
      return 'Повітропроводи'
    case 'ventAccessories':
      return 'Комплектуючі вентиляції'
    case 'chimneySystems':
      return 'Димохідні системи'
    case 'services':
      return 'Послуги'

    default:
      return type as string
  }
}

import React from 'react'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box } from '@mui/material'

import { Autoplay, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

import { SliderCard } from 'src/components/sliderCard'

import { sliderCards } from 'src/utils/constants/slider'

import 'swiper/css'
import 'swiper/css/scrollbar'

import styles from './slider.module.scss'

export const SliderMain = () => {
  const SwiperButtonPrev: React.FC = () => {
    const swiper = useSwiper()

    return (
      <Box
        className={`${styles.arrowBtn} ${styles.arrowBtnLeft}`}
        onClick={() => swiper.slidePrev()}
      >
        <ArrowBackIosIcon />
      </Box>
    )
  }

  const SwiperButtonNext: React.FC = () => {
    const swiper = useSwiper()

    return (
      <Box
        className={`${styles.arrowBtn} ${styles.arrowBtnRight}`}
        onClick={() => swiper.slideNext()}
      >
        <ArrowForwardIosIcon />
      </Box>
    )
  }

  return (
    <>
      <Swiper
        pagination={{
          clickable: true
        }}
        modules={[Mousewheel, Autoplay]}
        slidesPerView={3}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        loop={true}
        breakpoints={{
          360: {
            slidesPerView: 1
          },
          410: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 2
          },
          800: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1300: {
            slidesPerView: 3
          }
        }}
      >
        {sliderCards.map((el, index) => (
          <SwiperSlide key={index}>
            <SliderCard images={el.img} link={`/catalog#${el.hash}`} withoutBtn={true} />
          </SwiperSlide>
        ))}

        <Box>
          <SwiperButtonPrev />
          <SwiperButtonNext />
        </Box>
      </Swiper>
    </>
  )
}

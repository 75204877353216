import React from 'react'

import { Box, Typography, Icon } from '@mui/material'

interface IProps {
  icon: React.ReactNode
  children?: React.ReactNode
  onClick?: () => void
}

export const TextWithIcon: React.FC<IProps> = ({ icon, onClick, children }) => {
  return (
    <Box onClick={onClick} sx={{ display: 'flex', gap: '10px' }}>
      <Icon>{icon}</Icon>
      <Typography variant='h5'>{children}</Typography>
    </Box>
  )
}

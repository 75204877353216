import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Container } from '@mui/material'

import { CustomTitle } from 'src/components/customTitle'

import { AirDucts } from './airDucts'
import { ChimneySystems } from './chimneySystems'

import { getCatalogName } from 'src/utils/parse/catalog'

export const AboutProduct: React.FC = () => {
  const { product } = useParams()

  const renderContent = useCallback(() => {
    switch (product) {
      case 'airDucts':
        return <AirDucts />
      case 'chimneySystems':
        return <ChimneySystems />
      default:
        return <AirDucts />
    }
  }, [product])

  return (
    <Box>
      <Container>
        <CustomTitle title={getCatalogName( product) } />
        {renderContent()}
      </Container>
    </Box>
  )
}

import React from 'react'

import { Typography, Box } from '@mui/material'

import styles from './title.module.scss'

interface IProps {
  title: string
}

export const CustomTitle: React.FC<IProps> = ({ title }) => {
  return (
    <Box className={styles.title}>
      <Typography variant='h2'>{title}</Typography>
    </Box>
  )
}

import React, { BaseSyntheticEvent, useState } from 'react'
import { useParams } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material'

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from 'src/firebase-config'
import { setNewDocument, updateNestingInDocument } from 'src/lib/firebase/grid.services'

import { Loader } from 'src/components/loader'

import { ImageUploader } from '../documentUploader'
import { fileToImgSrc } from 'src/utils/parse/common'

import styles from './modal.module.scss'

interface IProps {
  isOpen: boolean
  setModalClose: (value: boolean) => void
  nesting: ICatalogItem[]
  getContent: () => void
}

export const ModalChange: React.FC<IProps> = ({ isOpen, setModalClose, nesting, getContent }) => {
  const [loader, setLoader] = useState(false)
  const [imgList, setImgList] = useState<File[]>([])
  const [imgTable, setImgTable] = useState<File>()
  const [data, setData] = useState<ICatalogItem>({
    name: '',
    id: '',
    serialNumber: '',
    title: '',
    tableImg: '',
    img: [],
    label: '',
    sizeType: {
      type: 'square',
      width: '',
      height: ''
    },
    description: [''],
    nesting: {}
  })

  const { product, type } = useParams()

  const uploadImgToStorage = (files: File[]) =>
    Promise.all(
      files.map((file) => {
        const name = new Date().getTime() + file.name
        const storageRef = ref(storage, name)
        const uploadTask = uploadBytesResumable(storageRef, file)

        return new Promise((resolve) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
                  break
              }
            },
            (error) => {
              alert(`Виникла помилка: ${error.message}`)
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
              resolve(downloadURL)
            }
          )
        })
      })
    )

  const handleSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault()
    setLoader(true)

    try {
      const listOfImg = await uploadImgToStorage(imgList)
      const tableImg = imgTable ? await uploadImgToStorage([imgTable]) : ['']

      if (type) {
        const res = Object.fromEntries(nesting.map((k) => [k.id, k]))
        const newData = {
          [data.id]: { ...data, img: listOfImg as string[], tableImg: tableImg[0] as string }
        }

        await updateNestingInDocument(product || '', type, Object.assign({}, res, newData))
      } else {
        await setNewDocument(product || '', data.id, {
          ...data,
          img: listOfImg as string[],
          tableImg: tableImg[0] as string
        })
      }

      setLoader(false)
      getContent()
    } catch (error) {
      alert(error)
      setLoader(false)
    }
  }

  const handleFileUpload = (file: File) => setImgTable(file)

  const handleFileListUpload = (file: File) =>
    setImgList((prev: File[]) => {
      return [...prev, file]
    })

  const onInputChange = (event: BaseSyntheticEvent) => {
    const { id, value } = event.currentTarget

    setData((prev) => {
      return { ...prev, [id]: value }
    })
  }

  const onSizeTypeChange = (event: BaseSyntheticEvent | SelectChangeEvent) => {
    const { name, value } = !!event.currentTarget ? event.currentTarget : event.target

    setData((prev) => {
      return { ...prev, sizeType: { ...prev.sizeType, [name]: value } }
    })
  }

  const onDescriptionChange = (event: BaseSyntheticEvent) => {
    const { id, value } = event.currentTarget

    const newArr = [...data.description]
    newArr[id] = value

    setData((prev) => {
      return { ...prev, description: [...newArr] }
    })
  }

  const delDescription = (id: number) => {
    const newArr = [...data.description]

    setData((prev) => {
      return { ...prev, description: [...newArr.filter((el, index) => index !== id)] }
    })
  }

  const addDescription = () => {
    console.log()

    setData((prev) => {
      return { ...prev, description: [...prev.description, ...['']] }
    })
  }

  const handleImgDel = (index: number | 'all') => {
    if (typeof index === 'number') {
      setImgList(
        imgList.filter((el, i) => {
          return index !== i && el
        })
      )
    } else {
      setImgTable(undefined)
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setModalClose(false)
      }}
    >
      <>
        {loader && <Loader />}
        <Box className={styles.content}>
          <Typography variant='h2' sx={{ textAlign: 'center', marginBottom: '20px' }}>
            id: {type}
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
              <Typography sx={{ width: '100%' }}>Список зображень:</Typography>
              {imgList.map((el, index) => {
                return (
                  <Box key={index} sx={{ position: 'relative' }}>
                    <img width={100} height={100} src={fileToImgSrc(el)} alt='' />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '-9px',
                        right: '4px',
                        fontSize: 42,
                        color: 'red',
                        transform: 'rotateZ(44deg)',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        handleImgDel(index)
                      }}
                    >
                      +
                    </Box>
                  </Box>
                )
              })}
              <ImageUploader onChange={handleFileListUpload} btnText={'+'} />
            </Box>

            <TextField
              id='id'
              label='Унікальний id ТРАНСЛІТОМ'
              fullWidth
              onChange={onInputChange}
              value={data.id}
            />
            <TextField
              id='serialNumber'
              label='Порядковый номер в списке'
              fullWidth
              type='number'
              onChange={onInputChange}
              value={data.serialNumber}
            />
            <TextField
              id='name'
              label='Назва продукту у списку'
              fullWidth
              onChange={onInputChange}
              value={data.name}
            />
            <TextField
              id='title'
              label='Заголовок детального виду (якщо нема вкладеності)'
              fullWidth
              onChange={onInputChange}
              value={data.title}
            />


            <TextField
              id='label'
              label='Лейбел продукту у калькуляторі'
              fullWidth
              onChange={onInputChange}
              value={data.label}
            />
            <Select
              id='type'
              name='type'
              value={data.sizeType.type}
              label='Тип рищітки'
              onChange={onSizeTypeChange}
            >
              <MenuItem value={'square'}>Квадрат</MenuItem>
              <MenuItem value={'rectangle'}>Прямокутник</MenuItem>
            </Select>
            <TextField
              id='width'
              name='width'
              label='Початкова довжина для калькулятору'
              fullWidth
              type='number'
              onChange={onSizeTypeChange}
              value={data.sizeType.width}
            />
            <TextField
              id='height'
              name='height'
              label='Початкова висота для калькулятору'
              fullWidth
              type='number'
              onChange={onSizeTypeChange}
              value={data.sizeType.height}
            />

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
              <Typography sx={{ width: '100%' }}>Таблиця з цінами:</Typography>
              <ImageUploader onChange={handleFileUpload} btnText={'+'} />
              {imgTable && (
                <Box sx={{ position: 'relative' }}>
                  <img width={100} height={100} src={fileToImgSrc(imgTable)} alt='' />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-9px',
                      right: '4px',
                      fontSize: 42,
                      color: 'red',
                      transform: 'rotateZ(44deg)',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      handleImgDel('all')
                    }}
                  >
                    +
                  </Box>
                </Box>
              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {data.description.map((el, index) => (
                <Box key={index} sx={{ position: 'relative' }}>
                  <TextField
                    label='Опис продукції'
                    id={`${index}`}
                    fullWidth
                    onChange={onDescriptionChange}
                    multiline
                    rows={3}
                    value={el}
                    sx={{ paddingRight: '20px' }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      right: '-10px',
                      transform: 'translateY(-50%)'
                    }}
                    onClick={() => {
                      delDescription(index)
                    }}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              ))}
              <Button
                onClick={() => {
                  addDescription()
                }}
                sx={{ color: '#FFF', maxWidth: '50px', marginLeft: 'auto' }}
              >
                <AddIcon />
              </Button>
            </Box>

            <Button type='submit' sx={{ color: '#FFF' }}>
              Додати
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  )
}

import React, { useState, BaseSyntheticEvent } from 'react'

import { useNavigate } from 'react-router-dom'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Button, TextField, Box, Typography, Container, Avatar } from '@mui/material'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuth } from 'src/containers/authContext'
import { auth } from 'src/firebase-config'

export const Login = () => {
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const { currentUser, logout } = useAuth()

  const navigate = useNavigate()

  const handleSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault()

    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
      alert('Ви успішно автиризувалися! ')
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Container
      sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {currentUser?.uid ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            marginTop: 8,
            marginBottom: 9
          }}
        >
          <Button onClick={() => navigate('/change')} variant='contained' sx={{ mt: 3, mb: 2 }}>
            До змін!
          </Button>

          <Button onClick={logout} variant='contained' sx={{ mt: 3, mb: 2 }}>
            Вихід
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 9,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant='h2'>Авторизація</Typography>
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              label='Email'
              name='email'
              type='email'
              onChange={(event) => {
                setLoginEmail(event.target.value)
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Пароль'
              type='password'
              onChange={(event) => {
                setLoginPassword(event.target.value)
              }}
            />
            <Button
              type='submit'
              size='large'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2, maxWidth: 'initial' }}
            >
              Увійти!
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  )
}

import React from 'react'

import { Box, Container, Typography } from '@mui/material'

import { CustomTitle } from 'src/components/customTitle'

import services from 'src/assets/services/services.jpg'

import styles from './services.module.scss'

export const Services: React.FC = () => {
  return (
    <Box>
      <Container>
        <CustomTitle title={'Послуги'} />

        <Typography variant='body2'>
          Вентбуд Інжиніринг – команда професіоналів, яка працює разом з клієнтом для задоволення
          його потреб по якості, асортименту, терміну виготовлення та ціні. Звернувшись до нас, ви
          можете розраховувати на кваліфіковану допомогу та якість послуг, а саме :
        </Typography>

        <Box className={styles.content}>
          <ul className={styles.list}>
            <li>Вентиляція повітря;</li>
            <li>Кондиціювання повітря;</li>
            <li>Теплопостачання;</li>
            <li>Холодопостачання;</li>
            <li>Опалення;</li>
            <li>Водопостачання;</li>
            <li>Каналізація;</li>
            <li>Монтаж димохідних систем;</li>
            <li>Автоматизація та диспетчеризація вентиляційних систем.</li>
          </ul>

          <Box>
            <img src={services} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

import { Breadcrumbs, Typography } from '@mui/material'

import styles from './breadcrumbs.module.scss'

interface Ilist {
  name: string
  link: string
}

interface IProps {
  list: Ilist[]
  mode: 'change' | 'view'
}

export const CustomBreadcrumbs: React.FC<IProps> = ({ list, mode }) => {
  return (
    <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
      <Link to={mode === 'change'? '/change': '/'}>
        <Typography color='inherit'>Головна</Typography>
      </Link>

      {list.map((el, index) => {
        return (
          <Link key={index} to={el.link}>
            <Typography color='inherit'>{el.name}</Typography>
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button } from '@mui/material'

import { moreInfo } from 'src/utils/constants/main'

import { useWindowDimensions } from 'src/utils/hooks/common'

import styles from './moreInfo.module.scss'

export const MoreInfo: React.FC = () => {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  return (
    <Box className={styles.root}>
      <Box className={styles.image}>
        <Box
          sx={{
            padding: '23%',
            background: `url(${width >= 769 ? moreInfo.imagesDes : moreInfo.imagesMob}) center no-repeat`,
            backgroundSize: 'cover'
          }}
        />
      </Box>
      <Box className={styles.overlay}>
        <Box className={styles.title}>{moreInfo.text}</Box>
        <Box className={styles.linkBtn}>
          <Button variant='contained' size='large' onClick={() => navigate('/services')}>
            {moreInfo.button}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

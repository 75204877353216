import React, { useEffect, useState, useContext } from 'react'

import { onAuthStateChanged, User } from 'firebase/auth'
import { auth } from 'src/firebase-config'

interface IProps {
  children: React.ReactNode
}

interface IValue {
  currentUser: User | null
  logout: () => void
  setLoader: (value: boolean) => void
  loader: boolean
}

const defaultState = {
  currentUser: null,
  logout: () => {},
  setLoader: () => {},
  loader: false
}

const AuthContext = React.createContext<IValue>(defaultState)

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>()
  const [loader, setLoader] = useState<boolean>(false)

  const logout = async () => {
    await auth.signOut()
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    loader,
    logout,
    setLoader
  }

  return <AuthContext.Provider value={value as IValue}>{children}</AuthContext.Provider>
}

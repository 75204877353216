import React from 'react'

import { Box, CircularProgress } from '@mui/material'

export const Loader: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: 'white',
        zIndex: 1,
        borderRadius: '10px'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
        //   height: '100%',
        //   width: '50%',
          zIndex: 2,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  )
}

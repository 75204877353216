import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Box, Container, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { getProductByTag } from 'src/lib/firebase/grid.services'

import { CustomBreadcrumbs } from 'src/components/breadcrumbs'
import { CatalogCard } from 'src/components/catalogCard'
import { CustomTitle } from 'src/components/customTitle'
import { Loader } from 'src/components/loader'

import { getCatalogName } from 'src/utils/parse/catalog'

import styles from './catalog.module.scss'

export const CatalogList: React.FC = () => {
  const [title, setTitle] = useState('')
  const [loader, setLoader] = useState(false)
  const [content, setContent] = useState<ICatalogItem[]>([])
  const [crumbs, setCrumbs] = useState([
    {
      name: '',
      link: ''
    }
  ])

  const { product, type } = useParams()
  const { pathname } = useLocation()

  const productPath = pathname.split('/catalog/')[1]

  const getCardLink = (name: string, item: ICatalogItem) => {
    if (isEmpty(item.nesting)) return `/details/${productPath}/${name}`

    return name
  }

  const renderContent = (res: ICatalogItem[]) => {
    setCrumbs([
      {
        name: getCatalogName(product),
        link: `/catalog/${product}`
      }
    ])

    if (!!type) {
      res.forEach((el: ICatalogItem) => el.id === type && setContent(Object.values(el.nesting)))

      const itemName = res
        .map((el: ICatalogItem) => el.id === type && el.name)
        .filter((el) => !!el)
        .join()
      setTitle(itemName)
      setCrumbs((prev) => {
        return [...prev, { link: `/catalog/${product}`, name: itemName }]
      })
    } else {
      setTitle(getCatalogName(product))
      setContent(res)
    }
  }

  const getContent = async () => {
    setLoader(true)

    try {
      const res = await getProductByTag(product || '')
      renderContent(res as ICatalogItem[])
    } catch (error) {
      return alert(`Щось пішло не так: ${error}`)
    } finally {
      setLoader(false)
    }
  }

  const sortItems = (contentList: ICatalogItem[]) => {
    return contentList.sort(function (a, b) {
      if (+a.serialNumber > +b.serialNumber) {
        return 1
      }
      if (+a.serialNumber < +b.serialNumber) {
        return -1
      }
      return 0
    })
  }

  useEffect(() => {
    getContent()
  }, [product, type])

  return (
    <Box>
      {loader ? (
        <Loader />
      ) : (
        <Container>
          <CustomTitle title={title} />

          <CustomBreadcrumbs list={crumbs} mode={'view'} />

          <Box className={styles.cardContainer}>
            {content && content.length > 0 ? (
              sortItems(content).map((el, index) => {
                return (
                  <CatalogCard
                    key={index}
                    name={el.name}
                    images={el.img[0]}
                    link={getCardLink(el.id, el)}
                  />
                )
              })
            ) : (
              <Typography variant='h2'>Список порожній!</Typography>
            )}
          </Box>
        </Container>
      )}
    </Box>
  )
}

import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import ReplyIcon from '@mui/icons-material/Reply'
import { Box, Container, Drawer, IconButton, Typography } from '@mui/material'
import classNames from 'classnames'


import variables from 'src/styles/variables.scss'

import { ReactComponent as LogoIcon } from 'src/assets/logo.svg'

import PdfImg from 'src/assets/mainPage/pdf.png'
import price from 'src/assets/pdfDownload/ventBudEngPrice.pdf'

import { TextWithIcon } from '../textWithIcon'

import { contactInfo, nav } from 'src/utils/constants/common'

import styles from './header.module.scss'

export const Header: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const navigate = useNavigate()

  return (
    <Box className={styles.root}>
      <Box className={styles.content}>
        <Container>
          <Box className={styles.addressBlock}>
            <Box className={classNames(styles.contentItems, styles.link)}>
              {contactInfo.map((el, index) => (
                <TextWithIcon key={index} icon={<el.icon />}>
                  {!el.href ? (
                    <Link key={index} to={el.to}>
                      {el.text}
                    </Link>
                  ) : (
                    <Typography key={index} component='a' href={el.href}>
                      {el.text}
                    </Typography>
                  )}
                </TextWithIcon>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>

      <Box className={styles.nav}>
        <Container>
          <Box className={styles.navBlock}>
            <Box className={styles.iconContainer}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={() => {
                  setIsOpen(true)
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box className={styles.logoMobile}>
              <IconButton
                color='inherit'
                aria-label='logo'
                onClick={() => {
                  navigate('/')
                }}
              >
                <LogoIcon />
              </IconButton>
            </Box>

            <Box className={styles.iconContainer}>
              <IconButton
                color='inherit'
                aria-label='backButton'
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ReplyIcon />
              </IconButton>
            </Box>
            <Box className={styles.listItem}>
              {nav.map((el, index) => (
                <Typography
                  key={index}
                  className={styles.item}
                  onClick={() => {
                    navigate(el.link)
                  }}
                >
                  {el.text}
                </Typography>
              ))}
              <Typography
                className={styles.downloadPrice}
                onClick={() => { 
                  window.open(price)
                }}
              >
                Завантажити прайс <img src={PdfImg} alt='pdf' />
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box>
        <Drawer
          anchor={'left'}
          open={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
        >
          <Box className={styles.sideBlock}>
            <Box className={styles.sideMenu}>
              {nav.map((el, index) => (
                <Typography
                  key={index}
                  className={styles.item}
                  onClick={() => {
                    navigate(el.link)
                    setIsOpen(false)
                  }}
                >
                  {el.text}
                </Typography>
              ))}
              <Typography
                className={styles.downloadPrice}
                onClick={() => { 
                  window.open(price)
                }}
              >
                Завантажити прайс <img src={PdfImg} alt='pdf' />
              </Typography>
            </Box>
            <Box className={styles.sideMenu}>
              {contactInfo.map((el, index) => {
                return (
                  el.type === 'phone' && (
                    <TextWithIcon key={index} icon={<el.icon />}>
                      <Typography
                        component='a'
                        href={el.href}
                        color={`${variables.backgroundSecond}`}
                      >
                        {el.text}
                      </Typography>
                    </TextWithIcon>
                  )
                )
              })}
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  )
}

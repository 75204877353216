import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Layout } from 'src/containers/layout'
import { Page404 } from 'src/pages/Page404'
import { AboutProduct } from 'src/pages/about'
import { CatalogList } from 'src/pages/catalog'
import { Change } from 'src/pages/change'
import { ContactUs } from 'src/pages/contactUs'
import { Details } from 'src/pages/details'
import { Login } from 'src/pages/login'
import { Main } from 'src/pages/main'
import { Services } from 'src/pages/services'

const Routers: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Main />} />

        <Route path='catalog'>
          <Route path=':product/:type?' element={<CatalogList />} />
        </Route>

        <Route path='details' element={<Details />}>
          <Route path=':product/:type/:model?' element={<Details />} />
        </Route>

        <Route path='about' element={<AboutProduct />}>
          <Route path=':product' element={<AboutProduct />} />
        </Route>

        <Route path='change' element={<Change />}>
          <Route path=':product?/:type?/' element={<Change />} />
        </Route>

        <Route path='contact-us' element={<ContactUs />} />
        
        <Route path='services' element={<Services />} />

        <Route path='login' element={<Login />} />

        <Route path='*' element={<div style={{ padding: 20 }}>not found</div>} />
      </Route>
      <Route path='404' element={<Page404 />} />
    </Routes>
  )
}

export default Routers

import React, { useEffect, BaseSyntheticEvent, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Divider, TextField } from '@mui/material'

import {
  crossingRec,
  crossingSqrBySize,
  gridsSizeRec,
  gridsSizeSquare
} from 'src/utils/constants/common'

import styles from './calc.module.scss'

interface IValuesInput {
  height: number
  width: number
  airFlow: number
  speed: number
  type: 'square' | 'rectangle'
}

interface IProps {
  item: ICatalogItem
}

export const DetailsCalc: React.FC<IProps> = ({ item }) => {
  const [values, setValues] = useState<IValuesInput>({
    height: 1,
    width: 1,
    airFlow: 50,
    speed: 0,
    type: 'rectangle'
  })

  const { type, model } = useParams()

  useEffect(() => {
    const height = +item.sizeType.height
    const width = +item.sizeType.width
    const speed = speedCalc({ ...values, height, width })
    const type = item.sizeType.type

    setValues((prev) => {
      return { ...prev, height, width, speed, type }
    })
  }, [item])

  const getDenominator = (width: number, height: number) => {
    if (!!model && type === 'kvadratnij-difuzor') {
      const sizeType = crossingSqrBySize?.[model as keyof typeof crossingSqrBySize] || '0.018'
      const sizePercent = sizeType[width as keyof typeof sizeType]

      return +sizePercent * 3600
    }

    return +crossingRec[type as keyof typeof crossingRec] * 3600 * (height / 1000) * (width / 1000)
  }

  const speedCalc = ({ height, width, airFlow }: IValuesInput) =>
    +(+airFlow / getDenominator(width, height)).toFixed(5)

  const onInputChange = (e: BaseSyntheticEvent) => {
    const { id, value } = e.currentTarget

    setValues((prev) => {
      return { ...prev, [id]: +value }
    })
  }

  const onInputBlur = (e: BaseSyntheticEvent) => {
    const { id, value } = e.currentTarget

    if (id === 'airFlow') {
      const newSpeed = speedCalc({ ...values, airFlow: value })
      setValues((prev) => {
        return { ...prev, airFlow: +value, speed: newSpeed }
      })
      return
    }

    const closestFrom = values.type === 'square' ? gridsSizeSquare : gridsSizeRec
    const closest = closestFrom.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0]

    const newInputValue =
      values.type === 'square' ? { width: +closest, height: +closest } : { [id]: +closest }

    const newSpeed = speedCalc({ ...values, ...newInputValue })

    setValues((prev) => {
      return { ...prev, ...newInputValue, speed: newSpeed }
    })
  }

  return (
    <Box className={styles.calcBlock}>
      <Box className={styles.productType}>{item.label}</Box>
      <Box className={styles.inputSize}>
        <TextField
          id='width'
          label='Довжина (мм.)'
          type='number'
          onChange={onInputChange}
          onBlur={onInputBlur}
          value={values.width.toString()}
          placeholder='300'
        />
        <Box>X</Box>
        <TextField
          id='height'
          label='Висота (мм.)'
          type='number'
          onChange={onInputChange}
          onBlur={onInputBlur}
          value={values.height.toString()}
          placeholder='300'
        />
      </Box>

      <TextField
        id='airFlow'
        fullWidth={true}
        label={
          <>
            Витрата повітря М<sup>3</sup>/год.
          </>
        }
        type='number'
        onChange={onInputChange}
        onBlur={onInputBlur}
        value={values.airFlow.toString()}
        placeholder='50'
      />
      <Divider variant='middle' textAlign='center' sx={{ width: '100%' }}>
        Результат
      </Divider>

      <Box className={styles.resultBlock}>
        <TextField
          id='speed'
          value={values.speed}
          label='Швидкість м/с'
          type='number'
          InputProps={{
            readOnly: true
          }}
        />
      </Box>
    </Box>
  )
}

import aboutUs from 'src/assets/mainPage/aboutUs.jpg'
import services from 'src/assets/mainPage/mainService.jpg'
import servicesMob from 'src/assets/mainPage/mainServiceMob.jpg'

export const benefits = {
  items: [
    'Choose your helper by reviews, skills and price',
    'Wonderful and TRUSTED helpers at your fingertips',
    'Search and schedule your sitter through one platform'
  ],
  title: 'Everyday Life Made Easier',
  description:
    'When life gets busy, you don`t have to tackle it alone. IsraelSitters is an easy platform in which you choose the help that is perfect for your family`s needs...',
  images: aboutUs
}

export const moreInfo = {
  text: 'Послуги, які ми надаємо',
  button: 'Читати',
  imagesDes: services,
  imagesMob: servicesMob,
}

import React, { useState } from 'react'
import Zoom from 'react-medium-image-zoom'

import Box from '@mui/material/Box/Box'
import { FreeMode, Navigation, Thumbs, Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'react-medium-image-zoom/dist/styles.css'

import styles from './slider.module.scss'

interface IProps {
  img: string[]
}

export const SliderDetails: React.FC<IProps> = ({ img }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperInterface | null>(null)

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation={img.length > 1}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Thumbs]}
        className={styles.mySwiper1}
      >
        {img.map((el, index) => {
          return (
            <SwiperSlide key={index} className={styles.contentContainer}>
              <Zoom>
                <Box
                  role='img'
                  style={{
                    background: `url(${el}) center no-repeat`,
                    backgroundSize: 'cover',
                    padding: '41%'
                  }}
                />
              </Zoom>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {img.length > 1 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={false}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className='mySwiper'
        >
          {img.map((el, index) => {
            return (
              <SwiperSlide key={index}>
                <Box
                  style={{
                    background: `url(${el}) center no-repeat`,
                    backgroundSize: 'cover',
                    padding: '41%'
                  }}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </>
  )
}

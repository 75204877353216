import { doc, getDoc, collection, getDocs, setDoc, updateDoc, deleteDoc } from 'firebase/firestore'
import { db } from 'src/firebase-config'

export const getProductByKey = async (product: string, key: string) => {
  const docRef = doc(db, product, key)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    console.log('No such document!')
  }
}

export const getProductByTag = async (product: string) => {
  const data = await getDocs(collection(db, product))

  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
}

export const setNewDocument = async (docName: string, key: string, data: ICatalogItem) => {
  await setDoc(doc(db, docName, key), data)
}

export const updateNestingInDocument = async (docName: string, key: string, data: INesting) => {
  await updateDoc(doc(db, docName, key), { nesting: { ...data } })
}

export const deleteDocumentById = async (docName: string, key: string) => {
  await deleteDoc(doc(db, docName, key))
}

import React from 'react'

import { Box, Typography } from '@mui/material'

import { benefits } from 'src/utils/constants/main'

import styles from './benefits.module.scss'

export const Benefits: React.FC = () => {
  return (
    <Box className={styles.root}>
      <Box className={styles.text}>
        <Typography variant='h2' className={styles.title}>
          Про нас:
        </Typography>
        <Typography variant='body1' className={styles.description}>
          Ми пропонуємо широкий вибір комплектуючих, виготовлених нашим підприємством якісно і в
          короткі терміни. Ми позиціонуємо себе як організація, яка виконує повний цикл робіт від
          виготовлення і монтажу до обслуговування вентиляційних систем.
        </Typography>
        <Typography variant='body1' className={styles.description}>
          Вентбуд Інжиніринг – команда професіоналів, яка працює разом з клієнтом для задоволення
          його потреб по якості, асортименту, терміну виготовлення та ціні. Звернувшись до нас, ви
          можете розраховувати на кваліфіковану допомогу та якість усіх видів вищезазначених послуг.
          Замовляючи продукцію нашої організації, Ви здійснюєте свій внесок у подальший розвиток
          вітчизняного виробництва.
        </Typography>
      </Box>

      <Box className={styles.imageBlock}>
        <Box
          style={{
            background: `url(${benefits.images}) center no-repeat`,
            backgroundSize: 'cover',
            padding: '39%',
            boxShadow: 'inset 0 0 10px 10px #f6f6f6',
            borderRadius: '57px',
            margin: '0 15px'
          }}
        />
      </Box>
    </Box>
  )
}

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography } from '@mui/material'

import styles from './card.module.scss'

interface IProps {
  images: React.ReactNode
  name: string
  link: string
}

export const CatalogCard: React.FC<IProps> = ({ images, name, link }) => {
  const navigate = useNavigate()

  return (
    <Box
      className={styles.root}
      onClick={() => {
        navigate(link)
      }}
    >
      <Box
        sx={{
          padding: '41%',
          background: `url(${images}) center no-repeat`,
          backgroundSize: 'cover'
        }}
      />

      <Typography variant='body1' className={styles.title}>
        {name}
      </Typography>

      <Button
        variant='contained'
        className={styles.btn}
        size='medium'
        onClick={() => {
          navigate(link)
        }}
      >
        Детальніше
      </Button>
    </Box>
  )
}

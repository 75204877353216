import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Container, Typography } from '@mui/material'

import { ReactComponent as LogoIcon } from 'src/assets/logo.svg'

import { TextWithIcon } from '../textWithIcon'
import { contactInfo } from 'src/utils/constants/common'

import styles from './footer.module.scss'

export const Footer: React.FC = () => {
  return (
    <Box className={styles.root}>
      <Container>
        <Box className={styles.content}>
          <Box className={styles.imgContent}>
            <LogoIcon />
          </Box>

          <Box className={styles.contactInfo}>
            <Link to='/contact-us'>
              <Typography variant='h2'>Наші контакти</Typography>
            </Link>
            <Box className={styles.contactItems}>
              {contactInfo.map((el, index) => {
                return (
                  <TextWithIcon key={index} icon={<el.icon />}>
                    {!el.href ? (
                      <Link key={index} to={el.to}>
                        {el.text}
                      </Link>
                    ) : (
                      <Typography key={index} component='a' href={el.href}>
                        {el.text}
                      </Typography>
                    )}
                  </TextWithIcon>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

import React from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import { Footer } from 'src/components/footer'
import { Header } from 'src/components/header'

export const Layout: React.FC = () => {
  return (
    <>
      <Header />
      <Box component='main' sx={{ padding: '40px 0', minHeight: 'calc(100vh - 340px)' }}>
        <Outlet />
      </Box>
      <Footer />
    </>
  )
}
